import _toPropertyKey from "./toPropertyKey.js";
var exports = {};
var toPropertyKey = _toPropertyKey;
function _defineProperty(obj, key, value) {
  key = toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
exports = _defineProperty, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;